import { ActivatedRouteSnapshot } from '@angular/router';
import { BasePermission, InsufficientPermissionsError } from '@techniek-team/permissions';

enum ViewPermissionSubjects {
  VIEW_PAGE = 'VIEW_PAGE',
  VIEW_HQ_SEARCH = 'VIEW_HQ_SEARCH',
  VIEW_HQ_CANDIDATES = 'VIEW_HQ_CANDIDATES',
  VIEW_HQ_DIFFICULT_CASES = 'VIEW_HQ_DIFFICULT_CASES',
  VIEW_HQ_HOURS_APPROVAL = 'VIEW_HQ_HOURS_APPROVAL',
  VIEW_HQ_PAYOUT = 'VIEW_HQ_PAYOUT',
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_LOCATION_AGENDA = 'VIEW_LOCATION_AGENDA',
  VIEW_LOCATION_PAYOUT = 'VIEW_LOCATION_PAYOUT',
  VIEW_LOCATION_AWAITING_SLOTS = 'VIEW_LOCATION_AWAITING_SLOTS',
  VIEW_LOCATION_HOURS_APPROVAL = 'VIEW_LOCATION_HOURS_APPROVAL',
}

/**
 * This Permission can be used with the {@see TtPermissionModule} and is used to
 * check permission about assignments.
 */
export class ViewPermission extends BasePermission {
  /**
   * @inheritDoc
   */
  public override readonly subjects: typeof ViewPermissionSubjects = ViewPermissionSubjects;

  /**
   * @inheritDoc
   */

  public validate(
    subject: ViewPermissionSubjects,
    data: ActivatedRouteSnapshot | undefined = undefined,
  ): boolean {
    switch (subject) {
      case ViewPermissionSubjects.VIEW_PAGE:
        if (data === undefined) {
          throw new InsufficientPermissionsError();
        }
        return this.viewPage(data);
      case ViewPermissionSubjects.VIEW_HQ_PAYOUT:
        return this.is('admin');
      case ViewPermissionSubjects.VIEW_HQ_SEARCH:
      case ViewPermissionSubjects.VIEW_HQ_CANDIDATES:
      case ViewPermissionSubjects.VIEW_HQ_DIFFICULT_CASES:
      case ViewPermissionSubjects.VIEW_HQ_HOURS_APPROVAL:
        return this.is('schedulingTeam');
      case ViewPermissionSubjects.VIEW_LOCATION_AGENDA:
      case ViewPermissionSubjects.VIEW_LOCATION_PAYOUT:
      case ViewPermissionSubjects.VIEW_LOCATION_AWAITING_SLOTS:
      case ViewPermissionSubjects.VIEW_LOCATION_HOURS_APPROVAL:
        return this.is('locationManager');
      case ViewPermissionSubjects.VIEW_DASHBOARD:
        return this.is('locationManager') || this.is('schedulingTeam');
      default:
        return true;
    }
  }

  public viewPage(data: ActivatedRouteSnapshot): boolean {
    const path: string = data.url.length > 0 ? data.url[0].path : '';
    switch (path) {
      case 'home':
      case 'dashboard':
      case '':
        return this.validate(ViewPermissionSubjects.VIEW_DASHBOARD);
      case 'locatie':
        return this.validate(ViewPermissionSubjects.VIEW_LOCATION_PAYOUT);
      case 'hoofdkantoor':
        return this.validate(ViewPermissionSubjects.VIEW_HQ_PAYOUT);
      case 'agenda':
        return this.validate(ViewPermissionSubjects.VIEW_LOCATION_AGENDA);
      case 'daadwerkelijk-gewerkte-uren/doorgeven-begeleiders':
        return this.validate(ViewPermissionSubjects.VIEW_HQ_HOURS_APPROVAL);
      case 'daadwerkelijk-gewerkte-uren/doorgeven-vestigingsmanager':
        return this.validate(ViewPermissionSubjects.VIEW_LOCATION_HOURS_APPROVAL);
      case 'zoeken':
        return this.validate(ViewPermissionSubjects.VIEW_HQ_SEARCH);
      case 'moeilijke-gevallen':
        return this.validate(ViewPermissionSubjects.VIEW_HQ_DIFFICULT_CASES);
      case 'begeleiders':
        return this.validate(ViewPermissionSubjects.VIEW_HQ_CANDIDATES);
      case 'wachtend-op-shift':
        return this.validate(ViewPermissionSubjects.VIEW_LOCATION_AWAITING_SLOTS);
    }
    return true;
  }
}
