import { Routes } from '@angular/router';
import { oauthAuthenticationGuard, oauthCanMatch } from '@techniek-team/oauth';

export const ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@scheduler-frontend/login-page').then((m) => m.LoginPage),
    data: { preload: false },
  },
  {
    path: 'impersonate',
    loadComponent: () =>
      import('@scheduler-frontend/login-page').then((m) => m.ImpersonateComponent),
    data: { preload: false },
  },
  {
    path: 'geen-toegang',
    loadComponent: () => import('@scheduler-frontend/forbidden-page').then((c) => c.ForbiddenPage),
    data: { preload: false },
  },
  {
    path: 'niet-gevonden',
    loadComponent: () => import('@scheduler-frontend/not-found').then((m) => m.NotFoundComponent),
    data: { preload: false },
  },
  {
    path: '',
    canMatch: [oauthCanMatch],
    canActivate: [oauthAuthenticationGuard],
    loadChildren: () => import('./app/app.routing').then((m) => m.ROUTES),
    data: { preload: false },
  },
  {
    path: '**',
    redirectTo: 'niet-gevonden',
    data: { preload: false },
  },
];
