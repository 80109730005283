import { Action } from '@ngrx/store';
import { EffectToInit } from '../enums/effect-to-init.enum';
import { initEffectActions } from './init-effect.actions';

type EffectToInitMap = { [effect in EffectToInit]: { start: Action; stop: Action } };
export const effectToInitMap: EffectToInitMap = {
  [EffectToInit.SEARCH_QUERY_MANAGEMENT]: {
    start: initEffectActions.startSearchQueryManagement(),
    stop: initEffectActions.stopSearchQueryManagement(),
  },
  [EffectToInit.USER_SEARCH_FAVORITES_AND_RECENT_MANAGEMENT]: {
    start: initEffectActions.startUserSearchFavoritesAndRecentManagement(),
    stop: initEffectActions.stopUserSearchFavoritesAndRecentManagement(),
  },
  [EffectToInit.LOADING_SLOTS_BASED_ON_SYSTEM_SEARCH]: {
    start: initEffectActions.startLoadingSlotsBasedOnSystemSearch(),
    stop: initEffectActions.stopLoadingSlotsBasedOnSystemSearch(),
  },
  [EffectToInit.SCHEDULE_MANAGEMENT]: {
    start: initEffectActions.startScheduleManagement(),
    stop: initEffectActions.stopScheduleManagement(),
  },
  [EffectToInit.SELECTING_SLOTS]: {
    start: initEffectActions.startSelectingSlots(),
    stop: initEffectActions.stopSelectingSlots(),
  },
  [EffectToInit.SELECTED_SLOTS_MANAGEMENT]: {
    start: initEffectActions.startSelectedSlotsManagement(),
    stop: initEffectActions.stopSelectedSlotsManagement(),
  },
  [EffectToInit.SEARCH_QUERY_LOCATION_STATISTICS]: {
    start: initEffectActions.startSearchQueryLocationStatistics(),
    stop: initEffectActions.stopSearchQueryLocationStatistics(),
  },
  [EffectToInit.CANDIDATE_ASSIGN]: {
    start: initEffectActions.startCandidateAssign(),
    stop: initEffectActions.stopCandidateAssign(),
  },
  [EffectToInit.CREATE_SEARCH_QUERY_FOR_SEARCH_VIEW]: {
    start: initEffectActions.startCreateSearchQueryForSearchView(),
    stop: initEffectActions.stopCreateSearchQueryForSearchView(),
  },
  [EffectToInit.CREATE_SEARCH_QUERY_FOR_LOCATION_SCHEDULE]: {
    start: initEffectActions.startCreateSearchQueryForLocationSchedule(),
    stop: initEffectActions.stopCreateSearchQueryForLocationSchedule(),
  },
  [EffectToInit.PRODUCT_SCHEDULE_MANAGEMENT]: {
    start: initEffectActions.startProductScheduleManagement(),
    stop: initEffectActions.stopProductScheduleManagement(),
  },
  [EffectToInit.CANDIDATE_DETAILS_MANAGEMENT]: {
    start: initEffectActions.startCandidateDetailsManagement(),
    stop: initEffectActions.stopCandidateDetailsManagement(),
  },
  /** Approaches - start **/
  [EffectToInit.MANAGE_APPROACH_LISTS]: {
    start: initEffectActions.startManageApproachLists(),
    stop: initEffectActions.stopManageApproachLists(),
  },
  [EffectToInit.FETCHING_BEST_MATCHING_SLOTS_FOR_CANDIDATE]: {
    start: initEffectActions.startFetchingBestMatchingSlotsForCandidate(),
    stop: initEffectActions.stopFetchingBestMatchingSlotsForCandidate(),
  },
  [EffectToInit.CREATING_APPROACH_TO_SEND_TO_CANDIDATE]: {
    start: initEffectActions.startCreatingApproachToSendToCandidate(),
    stop: initEffectActions.stopCreatingApproachToSendToCandidate(),
  },
  [EffectToInit.HANDLE_CANDIDATE_REACTING_TO_APPROACH]: {
    start: initEffectActions.startHandleCandidateReactingToApproach(),
    stop: initEffectActions.stopHandleCandidateReactingToApproach(),
  },
  /** Approaches - end **/
  [EffectToInit.SHOW_ALTERNATIVE_SHIFTS]: {
    start: initEffectActions.startShowAlternativeSlots(),
    stop: initEffectActions.stopShowAlternativeSlots(),
  },
  [EffectToInit.SHOW_INLINE_ASSIGN_BUTTONS]: {
    start: initEffectActions.startShowInlineAssignButtons(),
    stop: initEffectActions.stopShowInlineAssignButtons(),
  },
  [EffectToInit.SHOW_ASSIGNMENT_AS_NOT_AVAILABLE_ASSIGNING]: {
    start: initEffectActions.startShowAssignmentAsNotAvailableAssigning(),
    stop: initEffectActions.stopShowAssignmentAsNotAvailableAssigning(),
  },
};
