import { inject, provideAppInitializer } from '@angular/core';
import { TtFeatureFlagsConfig } from './tt-feature-flags.config';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

export function provideTtFeatureFlags(options: TtFeatureFlagsConfig) {
  //eslint-disable-next-line @typescript-eslint/no-misused-spread
  options = { ...new TtFeatureFlagsConfig(), ...options };

  return [
    { provide: TtFeatureFlagsConfig, useValue: options },
    { provide: TtFeatureFlagsService },
    provideAppInitializer(() => {
      inject(TtFeatureFlagsService);
    }),
  ];
}
