import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { environment } from '@scheduler-frontend/environments';
import { consoleInDev } from '@techniek-team/common';
import { isDefined } from '@techniek-team/rxjs';
import { isEqual, omit } from 'lodash-es';
import { map } from 'rxjs';
import { EffectToInit } from '../enums/effect-to-init.enum';
import { effectToInitMap } from './effect-to-init-map';
import { initializeActions } from './initialize.actions';
import { InitializeSelectors } from './initialize.selectors';

@Injectable()
export class InitializeEffects {
  private actions$ = inject(Actions);

  private router = inject(Router);
  private location = inject(Location);

  private store = inject(Store);

  public activated = new Set<EffectToInit>([]);

  public readonly triggerInit = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(initializeActions.triggerInit),
        map((action) => {
          for (const effect of action.processes) {
            if (this.activated.has(effect)) {
              this.redispatchStartEffect(effect);
            }
          }
        }),
      );
    },
    { dispatch: false },
  );

  public readonly dispatchEffectsOnRouterNavigation = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        concatLatestFrom(() =>
          this.store.select(
            InitializeSelectors.selectRouteNestedDataKey<EffectToInit[] | undefined>(
              'effectsToInit',
            ),
          ),
        ),
        map(([_action, effectsToInit]) => {
          for (const effect of this.activated) {
            if (!(effectsToInit ?? []).includes(effect)) {
              this.deactivateEffect(effect);
            }
          }

          for (const effect of effectsToInit ?? []) {
            this.activateEffect(effect);
          }
        }),
      );
    },
    { dispatch: false },
  );

  public readonly addRouteHistory = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatLatestFrom(() => [
        this.store.select(InitializeSelectors.selectRouterState),
        this.store.select(InitializeSelectors.selectRouteNestedParams),
        this.store.select(InitializeSelectors.selectRouteNestedQueryParams),
        this.store.select(InitializeSelectors.selectRouteNestedData),
        this.store.select(InitializeSelectors.previousRoute),
      ]),
      map(([_action, route, params, queryParams, data, previous]) => {
        return initializeActions.addRouterHistory({
          history: {
            ...route,
            nestedParams: params,
            nestedQueryParams: queryParams,
            nestedData: data,
            routeId: [
              '/',
              ...InitializeSelectors.urlSerializer
                .parse(route.url)
                .root.children['primary'].segments.map((segment) => segment.path),
            ].join('/'),
          },
        });
      }),
    );
  });

  public readonly changeQueryParams = createEffect(() => {
    return this.actions$.pipe(
      ofType(initializeActions.changeCurrentRouteQueryParams),
      concatLatestFrom(() => [
        this.store.select(InitializeSelectors.currentRoute),
        this.store.select(InitializeSelectors.routeHistory),
      ]),
      map(([action, current, history]) => {
        const newValue = this.sanitizeValue(action.newValue);

        let currentQueryParams: Params = { ...current?.nestedQueryParams };

        if (isEqual(currentQueryParams[action.key], newValue)) {
          return initializeActions.void();
        }

        currentQueryParams = omit({ ...current?.nestedQueryParams }, [action.key]);

        if (!newValue) {
          return initializeActions.changeCurrentRouteQueryParamsSuccess({
            params: currentQueryParams,
          });
        }

        return initializeActions.changeCurrentRouteQueryParamsSuccess({
          params: { ...currentQueryParams, [action.key]: action.newValue },
        });
      }),
    );
  });

  public readonly refreshRouteOnQueryParamChange = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(initializeActions.changeCurrentRouteQueryParamsSuccess),
        concatLatestFrom(() => [
          this.store
            .select(InitializeSelectors.currentRoutePrimaryNavigationPath)
            .pipe(isDefined()),
          this.store.select(InitializeSelectors.selectRouteNestedParams),
          this.store.select(InitializeSelectors.selectRouteNestedData),
          this.store.select(InitializeSelectors.selectRouterState),
        ]),
        map(([action, route, params, data, routerState]) => {
          this.store.dispatch(
            initializeActions.addRouterHistory({
              history: {
                ...routerState,
                nestedParams: params,
                nestedQueryParams: action.params,
                nestedData: data,
                routeId: route.join('/'),
              },
            }),
          );
          this.location.replaceState(
            route.slice(1).join('/'),
            new HttpParams({ fromObject: action.params }).toString(),
          );
        }),
      );
    },
    { dispatch: false },
  );

  private deactivateEffect(effect: EffectToInit) {
    this.activated.delete(effect);
    this.dispatchStopEffect(effect);
  }

  private activateEffect(effect: EffectToInit) {
    if (!this.activated.has(effect)) {
      this.activated.add(effect);
      this.dispatchStartEffect(effect);
    }
  }

  private redispatchStartEffect(effect: EffectToInit) {
    const action = effectToInitMap[effect].start;
    this.store.dispatch(action);

    consoleInDev(environment.debug).group(
      `%c RE-DISPATCHED STOP ACTION:`,
      `background: #5dade2; color: #e8f8f5; font-weight: bold;`,
    );
    consoleInDev(environment.debug).groupEnd();
    consoleInDev(environment.debug).info(
      `%c RE-DISPATCHED ACTION: ${effectToInitMap[effect].start.type}`,
      `background: #5dade26e; color: #e8f8f5; font-weight: bold; padding: 4px; border-radius: 4px;`,
    );
  }

  private dispatchStartEffect(effect: EffectToInit) {
    const action = effectToInitMap[effect].start;
    this.store.dispatch(action);

    consoleInDev(environment.debug).group(
      `%c DISPATCHED ACTION:`,
      `background: #17a5896e; color: #f7f9f9; font-weight: bold; padding: 4px; border-radius: 4px;`,
    );
    consoleInDev(environment.debug).info(
      `\x1B[1m${action.type.match(/(\[.*?\])/)?.[1]}\x1B[m ${action.type.match(/\[.*?\] (.*)/)?.[1]}`,
    );
    consoleInDev(environment.debug).groupEnd();
  }

  private dispatchStopEffect(effect: EffectToInit) {
    const action = effectToInitMap[effect].stop;
    this.store.dispatch(action);

    consoleInDev(environment.debug).group(
      `%c DISPATCHED STOP ACTION:`,
      `background: #8736006e; color: #f7f9f9; font-weight: bold; padding: 4px; border-radius: 4px;`,
    );
    consoleInDev(environment.debug).info(
      `\x1B[1m${action.type.match(/(\[.*?\])/)?.[1]}\x1B[m ${action.type.match(/\[.*?\] (.*)/)?.[1]}`,
    );
    consoleInDev(environment.debug).groupEnd();
  }

  private sanitizeValue(
    value: string | string[] | null | undefined,
  ): string | string[] | undefined {
    switch (true) {
      case typeof value === 'string':
        if (value.trim() === '') {
          return undefined;
        }
        return value;
      case Array.isArray(value):
        if (value.length === 0) {
          return undefined;
        }
        return value;
      default:
        return undefined;
    }
  }
}
