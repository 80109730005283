import {
  ChangeDetectorRef,
  computed,
  Directive,
  inject,
  input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { createNotifier } from 'ngxtension/create-notifier';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

/**
 * To check if a toggle is enabled from within a Component template, you can use
 * the ttFeatureFlag and ttFeatureFlagNot directives:
 *
 * @example
 * ```angular-html
 *   <some-component *ttFeatureFlag="'test.toggle'"></some-component>
 *   <some-other-component *ttFeatureFlagNot="'test.toggle'"></some-other-component>
 * ```
 */
@Directive({
  selector: '[ttFeatureFlag],[*ttFeatureFlag]',
  standalone: true,
})
export class TtFeatureFlagDirective {
  private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);

  private readonly viewContainer = inject(ViewContainerRef);

  private readonly ttFeatureFlagsService = inject(TtFeatureFlagsService);

  private readonly changeDetectionRef = inject(ChangeDetectorRef);

  /**
   * boolean stating if the content within this directive is available.
   */
  private isVisible: boolean = false;

  public readonly ttFeatureFlag = input.required<string>();

  protected readonly onUpdate = createNotifier();

  protected readonly listenToTtFeatureFlag = computed(() => {
    this.onUpdate.listen();
    if (this.ttFeatureFlagsService.isEnabled(this.ttFeatureFlag())) {
      if (!this.isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isVisible = true;
      }
    } else {
      this.viewContainer.clear();
      this.isVisible = false;
    }
  });

  protected readonly onUpdateSubscriber = this.ttFeatureFlagsService.onUpdate.subscribe(() => {
    this.onUpdate.notify();
    this.changeDetectionRef.markForCheck();
  });
}
