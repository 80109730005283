import { ActivatedRouteSnapshot } from '@angular/router';
import { UserInterface } from '@techniek-team/common';
import { InvalidRoleError } from '@techniek-team/oauth';
import { PermissionService } from '@techniek-team/permissions';
import { ViewPermission } from '../../view.permission';

export function checkUserRoles(permissionService: PermissionService) {
  return async (currentUser: UserInterface | undefined, route: ActivatedRouteSnapshot) => {
    if (!currentUser) {
      throw new InvalidRoleError();
    }

    const isGranted: boolean = await permissionService.isGranted(
      ViewPermission,
      'VIEW_PAGE',
      route,
    );

    if (!isGranted) {
      throw new InvalidRoleError();
    }
  };
}
