import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { enableProdMode, LOCALE_ID } from '@angular/core';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NoPreloading, provideRouter, withPreloading, withViewTransitions } from '@angular/router';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideInitializeStore } from '@scheduler-frontend/data-access-initialize';
import { provideUsersStore } from '@scheduler-frontend/data-access-users';
import { environment } from '@scheduler-frontend/environments';
import { checkUserRoles } from '@scheduler-frontend/permissions';
import { feedbackIntegration } from '@sentry/angular';
import {
  Drivers,
  jsonLdInterceptor,
  provideStorage,
  TtPreloadingStrategy,
  withBackwardCompatibleIonicStorageProvider,
} from '@techniek-team/common';
import { provideTtFeatureFlags } from '@techniek-team/feature-flags';
import {
  authWithWhitelistedRoutesInterceptor,
  provideTtAuth,
  UserService,
  withClearStorageOnImpersonator,
  withLocalStorage,
  withUserRoles,
} from '@techniek-team/oauth';
import { PermissionService, provideTtPermissions } from '@techniek-team/permissions';
import {
  provideSentry,
  SentryEnvironment,
  withTracing,
  withUserFeedback,
} from '@techniek-team/sentry-web';
import { setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';
import { MainComponent } from './main.component';
import { ROUTES } from './main.routing';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeNl, 'nl');
setDefaultOptions({
  locale: nl,
  weekStartsOn: 1,
});

bootstrapApplication(MainComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: MAT_DATE_LOCALE, useValue: nl },
    provideAnimations(),
    provideIonicAngular({
      useSetInputAPI: true,
    }),
    provideStorage(
      {
        name: '__scheduler-frontend',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        tables: ['application', 'NgrxCache', 'NgrxCache-schedules', 'NgrxCache-awaitingFirstSlots'],
      },
      withBackwardCompatibleIonicStorageProvider(),
    ),
    provideHttpClient(withInterceptors([jsonLdInterceptor, authWithWhitelistedRoutesInterceptor])),
    provideRouter(
      ROUTES,
      withPreloading(environment.production ? TtPreloadingStrategy : NoPreloading),
      withViewTransitions(),
    ),
    provideDateFnsAdapter(),
    provideSentry(
      {
        environment: environment.environment,
        dsn: 'https://31bab22356a0415494b67b7b5a5493ef@errors.techniek-team.nl//31',
        release: environment.release,
        autoSessionTracking: true,
        enableTracing: true,
        tracePropagationTargets: [
          `${environment.scheduler.url}/${environment.scheduler.iri}`,
          `${environment.perza.url}/${environment.perza.iri}`,
          environment.mercure.hub,
        ],
        userService: UserService,
      },
      withTracing(),
      withUserFeedback({
        colorScheme: 'system',
        autoInject: false,
        useSentryUser: {
          name: 'username',
          email: 'email',
        },
      }),
    ),
    provideTtPermissions({
      roleHierarchy: {
        ROLE_SKOLEO_TT_PLANNER_ADMIN: [
          'admin',
          'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
          'ROLE_SKOLEO_TT_PLANNER_CLUSTER_MANAGEMENT',
          'ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM',
        ],
        ROLE_SKOLEO_TT_PLANNER_CLUSTER_MANAGEMENT: 'clusterManager',
        ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT: 'locationManager',
        ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM: 'schedulingTeam',
      },
      userService: UserService,
    }),
    provideTtAuth(
      {
        authority: environment.ssoConfig.authority,
        redirectUrl: environment.ssoConfig.redirectUri,
        postLogoutRedirectUri: `${environment.ssoConfig.redirectUri}/login`,
        unauthorizedRoute: '/login',
        forbiddenRoute: '/geen-toegang',
        clientId: environment.ssoConfig.clientId,
        //logLevel: LogLevel.Debug,
      },
      withLocalStorage('__scheduler-frontend'),
      withUserRoles(checkUserRoles, [PermissionService]),
      withClearStorageOnImpersonator(),
    ),
    provideTtFeatureFlags({
      url: environment.gitlabFeatureFlags.url,
      appName: 'skoleo-scheduler-front-end',
    }),
    provideStore(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: false,
        },
      },
    ),
    provideRouterStore(),
    !environment.production
      ? provideStoreDevtools({
          maxAge: 100, // Retains last 100 states
          logOnly: environment.environment !== SentryEnvironment.LOCAL, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          trace: true,
          connectInZone: true,
        })
      : [],
    { provide: LOCALE_ID, useValue: 'nl' },
    provideInitializeStore(),
    provideUsersStore(),
  ],
})
  //eslint-disable-next-line no-console
  .catch((err: unknown) => console.log(err));
