import { Directive, inject, input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BasePermission } from '../base-permission/base.permission';
import { PermissionUserInterface } from '../contracts/permission-user.interface';
import { PermissionService } from '../service/permission.service';

interface ClassConstructor<T> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;
}

@Directive({
  selector: '[ttIsGranted],[*ttIsGranted]',
  standalone: true,
})
//eslint-disable-next-line @angular-eslint/directive-class-suffix
export class IsGrantedDirectiveMock<U extends PermissionUserInterface = PermissionUserInterface>
  implements OnInit
{
  private readonly permissionService = inject<PermissionService<U>>(PermissionService);

  private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);

  private readonly viewContainer = inject(ViewContainerRef);

  public readonly permissionCls = input.required<ClassConstructor<BasePermission<U>>>({
    alias: 'ttIsGranted',
  });

  public readonly subject = input.required<string>({ alias: 'ttIsGrantedSubject' });

  public readonly extraArgs = input<unknown[] | undefined>([], { alias: 'ttIsGrantedExtraArgs' });

  public readonly ttIsGrantedElse = input<TemplateRef<unknown>>();

  private hasView: boolean = false;

  public ngOnInit(): void {
    this.permissionService
      .isGranted(this.permissionCls(), this.subject(), ...(this.extraArgs() ?? []))
      .then(
        (isGranted) => {
          this.viewContainer.clear();

          const ttIsGrantedElse = this.ttIsGrantedElse();
          if (isGranted) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          } else if (ttIsGrantedElse) {
            this.viewContainer.createEmbeddedView(ttIsGrantedElse);
            this.hasView = false;
          } else {
            this.hasView = false;
          }
        },
        () => (this.hasView = false),
      );
  }
}
